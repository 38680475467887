<span [formGroup]="formGroup.get('phone')" class="pl-0 pr-0" driverPhone>
  <mat-form-field
    [floatLabel]="floating"
    [ngClass]="{
      'label-on-other-focus-invalid': inValid,
      'green-label': hasAnyFocused() ? true : false
    }"
    class="col-2"
  >
    <input
      #phoneCountry
      (blur)="onBlurPhoneCountry()"
      formControlName="country"
      inputmode="tel"
      matInput
      placeholder="{{ 'COMMONS.PHONE' | translate }}"
    />
  </mat-form-field>
  <mat-form-field class="col-4">
    <input
      #phoneProvider
      (blur)="onBlurPhoneProvider()"
      (focus)="onFocusPhoneProvider()"
      (keydown.backspace)="phoneProvider.value.length === 0 ? phoneCountry.focus() : null"
      formControlName="provider"
      inputmode="tel"
      matInput
    />
  </mat-form-field>

  <mat-form-field class="col-6">
    <input
      #phoneOther
      (blur)="onBlurPhoneOther()"
      (focus)="onFocusPhoneOther()"
      (keydown.backspace)="phoneOther.value.length === 0 ? phoneProvider.focus() : null"
      formControlName="other"
      inputmode="tel"
      matInput
    />
  </mat-form-field>
  <div *ngIf="showErrorMesseage && !showLengthErrorMesseage" class="pl-2 ml-1 phone-error" style="min-width: 300px;">
    {{ "NOTIFICATION-MESSAGE.TELEPHONE-ERROR" | translate }}
  </div>
  <div *ngIf="showLengthErrorMesseage" class="pl-2 ml-1 phone-error" style="min-width: 300px;">
    {{ "NOTIFICATION-MESSAGE.TELEPHONE-LENGTH-ERROR" | translate }}
  </div>
</span>
