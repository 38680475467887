import { Injectable } from '@angular/core';
import { Verification } from '@app/core/types';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VerificationStore {
  public verification$: Observable<Verification>;
  private _verification: BehaviorSubject<Verification> = new BehaviorSubject<Verification>({});

  constructor() {
    this.verification$ = this._verification.asObservable();
  }

  public get remainingDaysForMediaFiles(): Observable<number> {
    return this.verification$.pipe(
      map((verification: Verification) => moment(verification.mediaUploadDeadline).diff(moment(), 'days'))
    );
  }

  public setVerification(v: Verification) {
    this._verification.next(v);
  }
}
