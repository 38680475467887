import { Component } from '@angular/core';

@Component({
  selector: 'clm-reload-notification',
  templateUrl: './reload-notification.component.html',
})
export class ReloadNotificationComponent {
  constructor() {}

  public reload(): void {
    window.location.reload();
  }
}
