<div *ngIf="isTraingingEnabled" class="warn-tape d-flex justify-content-center align-self-center w-100 position-fixed">
  <span class="d-flex justify-content-center align-self-center font-weight-bold">
    {{ "MENU.WARN" | translate }}
  </span>
</div>
<nav [ngClass]="{ 'warn-tape-padding': isTraining }" class="navbar container-fluid shadow">
  <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 offset-lg-2 p-0 d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center d-none">
      <div>
        <a [href]="homeUrl" class="mabisz-logo-lg d-inline-block align-middle"></a>
      </div>
      <div class="ml-3 mr-3">
        <span class="d-inline-block text-nowrap font-weight-bold">{{ "APP.NAME" | translate }}</span>
      </div>
    </div>
    <div class="d-flex align-items-start col-xl-8 col-lg-6 col-sm-6 p-0 m-0">
      <div class="d-flex">
        <div class="mb-2 d-flex align-content-between">
          <button
            (click)="openHomeDialog()"
            class="align-middle mt-1 text-mabisz-primary p-2 d-lg-flex align-items-center d-none header-menu-option"
            mat-button
          >
            <span class="align-middle text-center">{{ "MENU.SAFETY-NOTES" | translate }}</span>
          </button>
          <a
            (click)="openAszf()"
            class="align-middle mt-1 text-mabisz-primary p-2 d-lg-flex align-items-center d-none header-menu-option"
            mat-button
          >
            <span class="align-middle d-inline-block text-center">{{ "MENU.TERMS-AND-CONDITIONS" | translate }}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="col-1 d-lg-flex d-none justify-content-end pr-0">
      <span class="light-select">
        <mat-select (selectionChange)="onChangeLanguage($event.value)" [formControl]="defaultLang">
          <mat-option *ngFor="let language of languages; let i = index; trackBy: trackByFn" [value]="language.code">
            {{ language.name }}
          </mat-option>
        </mat-select>
      </span>
    </div>

    <div class="d-flex justify-content-start d-block d-lg-none pb-2">
      <mat-icon (click)="openSidenav()" class="btn mt-0 mb-2 p-0 mat-icon-md">menu</mat-icon>
    </div>
  </div>
</nav>
