import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DrawingVideoDialogComponent } from '../drawing-video-dialog/drawing-video-dialog.component';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { NotificationDialogData } from '../notification-dialog/NotificationDialogData';

type InfoButtonTheme = 'light' | 'dark';

@Component({
  selector: 'clm-info-button',
  templateUrl: './info-button.component.html',
})
export class InfoButtonComponent {
  @Input() public dialogData: NotificationDialogData;
  @Input() public theme: InfoButtonTheme = 'light';

  constructor(private dialog: MatDialog) {}

  public get buttonClass(): string {
    return `info-button-${this.theme}`;
  }

  public openInfoDialog($event: Event): void {
    $event.stopPropagation();
    if (!!this.dialogData && Object.keys(this.dialogData).length > 0) {
      if (!this.dialogData.video) {
        const config: MatDialogConfig = {
          data: this.dialogData,
        };
        this.dialog.open(NotificationDialogComponent, config);
      } else {
        const config: MatDialogConfig = {
          disableClose: true,
          data: this.dialogData,
          autoFocus: false,
          restoreFocus: false,
        };
        this.dialog.open(DrawingVideoDialogComponent, config);
      }
    }
  }
}
