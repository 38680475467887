export enum TerrainFeature {
  TREE, // fa
  GUARDRAIL, // szalagkorlát
  PEDESTRAIN_MAN, // férfiú
  PEDESTRAIN_WOMAN, // nőstényállat
  FENCE, // kerítés
  HOUSE_WALL, // ház fal
  BRICK_WALL, // tégla fal
  LAMP_1, // lamp egy
  LAMP_2, // lamp ketto
  ANTISOSTA, // parkolásgátló
  RAILROAD, // vasút
  CONCRETE_SLIT, // betonterlő
}
