<div class="d-flex" matDialogTitle>
  <div class="mr-auto">{{ "MEDIA-SENDING.TITLE" | translate }}</div>
  <clm-info-button
    [dialogData]="{ title: 'MEDIA-SENDING.TITLE', actionString: 'COMMONS.OK', text: 'MEDIA-SENDING.INFO' }"
    class="d-flex align-self-center"
    theme="dark"
  ></clm-info-button>
</div>

<mat-dialog-content style="min-height: 2em;">
  <div class="d-flex">
    <div class="d-flex justify-content-center position-relative w-100">
      <mat-progress-bar
        [value]="(successfullyUploadedMediaCount / (allMediaCount$ | async)) * 100"
        color="primary"
        mode="determinate"
        style="height: 25px;"
      ></mat-progress-bar>
      <div class="d-flex position-absolute" style="top: 0px; color: white;">
        {{ successfullyUploadedMediaCount / (allMediaCount$ | async) | percent }}
      </div>
    </div>
    <div
      *ngIf="
        ((isFinishedSendingMediaFiles$ | async) || (internalIsFinishedSending$ | async)) &&
        !((isFinishedSendingMediaFiles$ | async) && (internalIsFinishedSending$ | async))
      "
      class="d-flex"
    >
      <mat-progress-spinner [diameter]="25" class="ml-2" color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
    <div
      *ngIf="
        (isFinishedSendingMediaFiles$ | async) && (internalIsFinishedSending$ | async) && failedMediaFiles?.length === 0
      "
      class="d-flex ml-2"
    >
      <mat-icon svgIcon="pipa"></mat-icon>
    </div>
    <div
      *ngIf="
        (internalIsFinishedSending$ | async) && (isFinishedSendingMediaFiles$ | async) && failedMediaFiles?.length > 0
      "
      class="d-flex ml-2"
    >
      <mat-icon svgIcon="fail"></mat-icon>
    </div>
  </div>

  <div
    *ngIf="
      (internalIsFinishedSending$ | async) && (isFinishedSendingMediaFiles$ | async) && failedMediaFiles?.length > 0
    "
  >
    {{
      "MEDIA-SENDING.ERROR"
        | translate
          : {
              allFiles: allMediaCount,
              successfullyUploadedFiles: successfullyUploadedMediaCount,
              days: remainingDays$ | async
            }
    }}
  </div>

  <div
    *ngIf="
      (isFinishedSendingMediaFiles$ | async) && (internalIsFinishedSending$ | async) && failedMediaFiles?.length === 0
    "
  >
    {{ "MEDIA-SENDING.SUCCESS" | translate }}
  </div>
</mat-dialog-content>

<mat-dialog-actions
  [ngClass]="{
    'd-none':
      ((isFinishedSendingMediaFiles$ | async) || (internalIsFinishedSending$ | async)) &&
      !((isFinishedSendingMediaFiles$ | async) && (internalIsFinishedSending$ | async))
  }"
  class="d-flex justify-content-center flex-column"
>
  <button
    #retryBtn
    [ngClass]="{
      'd-none': !(
        (internalIsFinishedSending$ | async) &&
        (isFinishedSendingMediaFiles$ | async) &&
        failedMediaFiles?.length > 0
      )
    }"
    class="btn-rounded mb-2"
    color="primary"
    mat-raised-button
  >
    {{ "MEDIA-SENDING.RETRY" | translate }}
  </button>
  <button
    *ngIf="(isFinishedSendingMediaFiles$ | async) && (internalIsFinishedSending$ | async)"
    class="btn-rounded"
    color="primary"
    mat-button
    matDialogClose
  >
    {{ "MEDIA-SENDING.CLOSE" | translate }}
  </button>
</mat-dialog-actions>
