import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './../material-module/material.module';
import { SpinnerDialogComponent } from './spinner-dialog.component';

const components: any[] = [SpinnerDialogComponent];

@NgModule({
  declarations: [components],
  imports: [CommonModule, MaterialModule, TranslateModule],
  exports: [components],
  providers: [],
})
export class SpinnermDialogModule {}
