import { Level } from '@nsalaun/ng-logger';
import { buildDate, version } from '../_versions';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  homeUrl: '/',
  logLevel: Level.LOG,
  baseApiUrl: '/testdigiclaimapi',
  released: true,
  isTrainingMode: false,
  hmr: true,
  trainingUrl: 'https://training.digiclaim.suitsolutions.eu/',
  appVersion: version,
  emergencyPhoneNum: '111',
  firebaseConfig: {
    apiKey: 'AIzaSyBhKdbp8Aawivp2owkeP4JR2qvOZe4uAbo',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  accidentMap: {
    huCountryMapLocation: '/accident-map/hu-country-map.jpg',
    tilesBaseUrl: '/accident-map/tiles',
  },
  buildTime: buildDate,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
