import { Injectable } from '@angular/core';
import { FormMenu } from '@app/core/types';

@Injectable()
export class Constants {
  public static readonly validAreaCodes = [
    1,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    40,
    42,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    59,
    60,
    61,
    62,
    63,
    66,
    68,
    69,
    70,
    72,
    73,
    74,
    75,
    76,
    77,
    78,
    79,
    80,
    82,
    83,
    84,
    85,
    87,
    88,
    89,
    90,
    91,
    92,
    93,
    94,
    95,
    96,
    99,
  ];

  public readonly validatorList: FormMenu[] = this._validatorList;

  private get _validatorList(): FormMenu[] {
    return [
      {
        menu: 'ACCIDENT-INFO',
        contains: [
          {
            key: 'accidentDate',
            validators: {
              required: true,
              minDate: 30,
              custom: [],
            },
          },
          {
            key: 'accidentAddress.fullAddress',
            validators: {
              required: true,
              maxLength: 465,
              custom: [],
            },
          },
        ],
      },
      {
        menu: 'VEHICLE-INFO',
        contains: [
          {
            key: 'plateNumber',
            validators: {
              required: false,
              maxLength: 9,
              custom: [],
            },
          },
          {
            key: 'vin',
            validators: {
              required: false,
              maxLength: 20,
              custom: [],
            },
          },
          {
            key: 'product',
            validators: {
              required: false,
              maxLength: 43,
              custom: [],
            },
          },
          {
            key: 'typeCode',
            validators: {
              required: true,
              maxLength: 100,
              custom: [],
            },
          },
          {
            key: 'color',
            validators: {
              required: false,
              maxLength: 100,
              custom: [],
            },
          },
          {
            key: 'kind',
            validators: {
              required: false,
              maxLength: 50,
              custom: [],
            },
          },
          {
            key: 'contract.contractIdentifier',
            validators: {
              required: false,
              maxLength: 20,
              custom: [],
            },
          },
          {
            key: 'contract.contractValidity',
            validators: {
              required: false,
              custom: [],
            },
          },
          {
            key: 'contract.contractorFullname',
            validators: {
              required: false,
              maxLength: 110,
              custom: [],
            },
          },
          {
            key: 'contract.contractorCompanyName',
            validators: {
              required: false,
              maxLength: 110,
              custom: [],
            },
          },
          {
            key: 'contract.insCompCode',
            validators: {
              required: true,
              custom: [],
            },
          },
          {
            key: 'contract.contractorAddress.fullAddress',
            validators: {
              required: false,
              maxLength: 465,
              custom: [],
            },
          },
          {
            key: 'contract.contractorPhoneNum',
            validators: {
              required: false,
              maxLength: 20,
              custom: [],
            },
          },
          {
            key: 'contract.contractorEmail',
            validators: {
              required: false,
              email: true,
              maxLength: 100,
              custom: [],
            },
          },
          {
            key: 'contract.contractorBirthPlace',
            validators: {
              required: false,
              maxLength: 100,
            },
          },
          {
            key: 'contract.contractorBirthDate',
            validators: {
              required: false,
              custom: [],
            },
          },
          {
            key: 'contract.foreignInsurerName',
            validators: {
              required: false,
              custom: [],
            },
          },
          {
            key: 'contract.foreignInsurerCountryCode',
            validators: {
              required: false,
              maxLength: 30,
              custom: [],
            },
          },
        ],
      },
      {
        menu: 'VEHICLE-INFO-ATTACHMENT',
        contains: [
          {
            key: 'plateNumber',
            validators: {
              required: true,
              maxLength: 9,
              custom: [],
            },
          },
          {
            key: 'vin',
            validators: {
              required: false,
              maxLength: 20,
              custom: [],
            },
          },
          {
            key: 'product',
            validators: {
              required: false,
              maxLength: 43,
              custom: [],
            },
          },
          {
            key: 'typeCode',
            validators: {
              required: true,
              maxLength: 100,
              custom: [],
            },
          },
          {
            key: 'color',
            validators: {
              required: false,
              maxLength: 100,
              custom: [],
            },
          },
          {
            key: 'kind',
            validators: {
              required: false,
              maxLength: 50,
              custom: [],
            },
          },
          {
            key: 'contract.contractIdentifier',
            validators: {
              required: false,
              maxLength: 20,
              custom: [],
            },
          },
          {
            key: 'contract.contractValidity',
            validators: {
              required: false,
              custom: [],
            },
          },
          {
            key: 'contract.contractorFullname',
            validators: {
              required: false,
              maxLength: 110,
              custom: [],
            },
          },
          {
            key: 'contract.contractorCompanyName',
            validators: {
              required: false,
              maxLength: 110,
              custom: [],
            },
          },
          {
            key: 'contract.insCompCode',
            validators: {
              required: true,
              custom: [],
            },
          },
          {
            key: 'contract.contractorAddress.fullAddress',
            validators: {
              required: false,
              maxLength: 465,
              custom: [],
            },
          },
          {
            key: 'contract.contractorPhoneNum',
            validators: {
              required: false,
              maxLength: 20,
              custom: [],
            },
          },
          {
            key: 'contract.contractorEmail',
            validators: {
              required: false,
              email: true,
              maxLength: 100,
              custom: [],
            },
          },
          {
            key: 'contract.contractorBirthPlace',
            validators: {
              required: false,
              maxLength: 100,
            },
          },
          {
            key: 'contract.contractorBirthDate',
            validators: {
              required: false,
              custom: [],
            },
          },
          {
            key: 'contract.foreignInsurerName',
            validators: {
              required: false,
              custom: [],
            },
          },
          {
            key: 'contract.foreignInsurerCountryCode',
            validators: {
              required: false,
              maxLength: 30,
              custom: [],
            },
          },
        ],
      },
      {
        menu: 'DRIVERS',
        contains: [
          {
            key: 'fullname',
            validators: {
              required: true,
              maxLength: 110,
              custom: [],
            },
          },
          {
            key: 'drivingCardNumber',
            validators: {
              required: true,
              maxLength: 20,
              custom: [],
            },
          },
          {
            key: 'drivingCardValidity',
            validators: {
              required: false,
              custom: [],
            },
          },
          {
            key: 'address.fullAddress',
            validators: {
              required: false,
              maxLength: 465,
              custom: [],
            },
          },
          {
            key: 'phone.country',
            validators: {
              required: true,
              pattern: '([+])([1-9][0-9]{0,3})',
              maxLength: 5,
              minLength: 1,
              custom: [],
            },
          },
          {
            key: 'phone.provider',
            validators: {
              required: true,
              maxLength: 6,
              minLength: 1,
              custom: [],
            },
          },
          {
            key: 'phone.other',
            validators: {
              required: true,
              maxLength: 10,
              minLength: 1,
              custom: [],
            },
          },
          {
            key: 'email',
            validators: {
              required: true,
              email: true,
              maxLength: 100,
              custom: [],
            },
          },
          {
            key: 'birthPlace',
            validators: {
              required: true,
              maxLength: 100,
            },
          },
          {
            key: 'birthDate',
            validators: {
              required: true,
              custom: [],
            },
          },
        ],
      },
      {
        menu: 'RESPONSIBILITY',
        contains: [
          {
            key: 'responsibility',
            validators: {
              required: true,
              custom: [],
            },
          },
          {
            key: 'happendPoliceAction',
            validators: {
              required: false,
              maxLength: 100,
              custom: [],
            },
          },
          {
            key: 'policeHead',
            validators: {
              required: false,
              maxLength: 50,
              custom: [],
            },
          },
          {
            key: 'policeDocNumber',
            validators: {
              required: false,
              maxLength: 100,
              custom: [],
            },
          },
        ],
      },
    ];
  }
}
