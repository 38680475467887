import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './../material-module/material.module';
import { TelephoneComponent } from './telephone.component';

const components: any[] = [TelephoneComponent];

@NgModule({
  declarations: [components],
  imports: [CommonModule, MaterialModule, TranslateModule, FormsModule, ReactiveFormsModule],
  exports: [components],
  providers: [],
})
export class TelephoneModule {}
