<div [ngClass]="{ 'content-container col-md-12 col-lg-10 offset-lg-1': showHomeButton }">
  <mat-card [ngClass]="{ 'col-md-12 col-lg-10 offset-lg-1 mt-4': showHomeButton }" class="no-padding-card">
    <div class="w-100 bg-mabisz-primary text-center card-header-shadow">
      <h4 class="text-white p-3">
        {{ "HOME.ACCIDENT" | translate }}
      </h4>
    </div>
    <div class="text-center mr-4 ml-4 mt-4 pb-4">
      <div>
        <ul class="list-group-flush p-0">
          <li
            *ngFor="let num of stepCount$ | async; let i = index; trackBy: trackByFn"
            class="list-group-item text-left font-weight-bold"
          >
            <span class="col-3 stepbox step-{{ num }} align-middle"></span>
            <span *ngIf="num === 4; else simpleText" class="col-9 d-inline-block m-0 align-middle">
              <span class="d-none d-md-block d-lg-block d-xl-block">
                {{ "HOME.ACCIDENT-STEPS." + num | translate }}
              </span>
              <span
                (click)="openEmergencyCallSheet()"
                class="text-mabisz-primary cursor-pointer d-block d-md-none d-lg-none d-xl-none"
              >
                {{ "HOME.ACCIDENT-STEPS." + num | translate }}
              </span>
            </span>
            <ng-template #simpleText>
              <span class="col-9 d-inline-block m-0 align-middle">{{ "HOME.ACCIDENT-STEPS." + num | translate }}</span>
            </ng-template>
          </li>
        </ul>
      </div>
      <a *ngIf="showHomeButton" [routerLink]="['/claim', 'new', 'one']">
        <button class="p-2 btn-rounded bg-mabisz-primary text-white mt-3 col-10" mat-raised-button>
          {{ "HOME.NEXT-TO" | translate }}
        </button>
      </a>
    </div>
  </mat-card>
</div>
