import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { catchError, first, share } from 'rxjs/operators';
import { isNonEmptyString } from '../string/string.util';

export function appendParam(params: HttpParams, key: string, param: string): HttpParams {
  if (isNonEmptyString(param)) {
    return params.set(key, param);
  }
  return params;
}

export function dataURItoBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function getJsonResource<T = object>(httpClient: HttpClient, json: string): Observable<HttpResponse<T>> {
  const baseUrl = 'assets/config/';
  const url = baseUrl + json;

  return httpClient
    .get<T>(url, { responseType: 'json', observe: 'response' })
    .pipe(
      first(),
      share(),
      catchError((err) => {
        this.handleError(err);
        return EMPTY;
      })
    );
}

export function handleError(error) {
  this.log.debug(error);
}
