import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  public transform(url: string): SafeResourceUrl {
    if (url.includes('http') || url.includes('blob') || url.includes('local')) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return url;
  }
}
