import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Logger } from '@nsalaun/ng-logger';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { from, Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AccidentInfoQrContent } from '../../core/types/AccidentInfoQrContent';

@Component({
  selector: 'clm-qr-scanner-dialog',
  templateUrl: 'qr-scanner-dialog.component.html',
})
export class QrScannerDialogComponent implements AfterViewInit, OnDestroy {
  public currentDevice: MediaDeviceInfo = null;
  public devices: Observable<MediaDeviceInfo[]>;
  @ViewChild('scanner', { static: true }) public scanner: ZXingScannerComponent;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<QrScannerDialogComponent>, private logger: Logger) {}

  public close(): void {
    this.dialogRef.close();
  }

  public ngAfterViewInit(): void {
    this.devices = from(this.scanner.updateVideoInputDevices()).pipe(
      filter((devices) => devices && devices.length > 0),
      tap((devices) => {
        this.logger.debug(devices);
        this.currentDevice =
          devices.find((dev) => dev.label.toLowerCase().replace(/\s+/g, '').includes('facingback')) || devices[0];
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public readData(e: string) {
    const parsedJson: AccidentInfoQrContent = JSON.parse(e);
    this.dialogRef.close(parsedJson);
  }
}
