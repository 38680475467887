import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@nsalaun/ng-logger';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public dateAdapterLang$: Observable<string>;
  public defaultLang: FormControl;
  public languages: any[];
  private _dateLang: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(private translate: TranslateService, private logger: Logger) {
    this.dateAdapterLang$ = this._dateLang.asObservable();
    this.languages = [
      {
        name: 'HU',
        code: 'hu',
        dateLang: 'hu-HU',
      },
      {
        name: 'EN',
        code: 'en',
        dateLang: 'en-GB',
      },
    ];

    const browserLang = navigator.language.split('-')[0];
    const foundLang = this.languages.find(
      (v: { code: string; dateLang: string; name: string }) => v.code === browserLang
    );
    const defaultLanguage: string = (foundLang && foundLang.code) || 'en';
    this.defaultLang = new FormControl(defaultLanguage);
    this.changeLangue(defaultLanguage);
    this.setDateLang(defaultLanguage);
  }

  public changeLangue(lang: string) {
    this.translate.use(lang);
  }

  public setDateLang(lang: string): void {
    const dateLang = this.languages.find((val: { code: string; dateLang: string; name: string }) => val.code === lang)
      .dateLang;
    this._dateLang.next(dateLang);
    this.logger.debug(`"${dateLang}" has been set as dateAdapter language`);
  }
}
