import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import 'firebase/analytics';
import * as firebase from 'firebase/app';
import { timer } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { BUILD_TIME } from '@app/core';
import { NotificationDialogComponent } from '@app/shared/notification-dialog/notification-dialog.component';
import { environment } from '@env/environment';
import { DeviceDetectorService } from './core/device-detector/device-detector.service';
import { UpdateService } from './core/update-sw/update.service';
import { DownloadAppSheetComponent } from './download-app';
import { EmergencyCallSheetComponent } from './emergency-call';
import { NotificationDialogData } from './shared/notification-dialog/NotificationDialogData';

@Component({
  selector: 'clm-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  public title = 'Digi claim UI';

  constructor(
    @Inject(BUILD_TIME) public buildTime: string,
    private bottomSheet: MatBottomSheet,
    private deviceDetectorService: DeviceDetectorService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public updateServcie: UpdateService
  ) {
    this.translate.addLangs(['hu', 'en']);
    this.translate.setDefaultLang('hu');

    const browserLang: string = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/hu/) ? browserLang : 'hu');
  }

  public get isReleased(): boolean {
    return environment.released;
  }

  public closeSidenav() {
    this.sidenav.close();
  }

  public initFirebase(): void {
    if (environment.firebaseConfig && environment.firebaseConfig.measurementId) {
      firebase.initializeApp(environment.firebaseConfig);
      firebase.analytics();
    }
  }

  public isLanding(): boolean {
    return window.location.href.includes('landing') || window.location.hash.length === 0;
  }

  public ngAfterViewInit(): void {
    if (environment.production) {
      this.popupChecker();
    }
  }

  public ngOnInit(): void {
    this.showDownloadAppSheet();
    this.initFirebase();
  }

  public openDownloadAppSheet(): void {
    if (environment.production) {
      timer(3000)
        .pipe(
          tap(() => this.bottomSheet.open(DownloadAppSheetComponent)),
          first()
        )
        .subscribe();
    }
  }

  public openEmergencySheet(): void {
    this.closeSidenav();
    this.bottomSheet.open(EmergencyCallSheetComponent);
  }

  public toggleSidenav() {
    if (this.sidenav.opened) {
      this.closeSidenav();
    } else {
      this.sidenav.open();
    }
  }

  private popupChecker() {
    try {
      window.open('', '_blank').close();
    } catch (e) {
      const notificationDialogConfig = new MatDialogConfig<NotificationDialogData>();
      notificationDialogConfig.data = {
        text: 'LANDING.POPUP',
        actionString: 'COMMONS.NEXT',
      };
      this.dialog.open(NotificationDialogComponent, notificationDialogConfig);
    }
  }

  private showDownloadAppSheet() {
    if (this.deviceDetectorService.isMobile()) {
      this.openDownloadAppSheet();
    }
  }
}
