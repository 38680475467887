<div (click)="onClickViber()" *ngIf="!showViberChat" class="viber-chatbot cursor-pointer">
  <img alt="viber chatbot" src="assets/img/logo_icon_white_large.png" width="80px" />
</div>

<div (click)="onClickViber()" *ngIf="showViberChat" class="viber-off cursor-pointer mb-2">
  <mat-icon>highlight_off</mat-icon>
</div>

<div *ngIf="showViberChat" class="viber-chat">
  <div class="viber-header d-flex align-items-center">
    <img height="80px" src="assets/icon/android-icon-192x192.png" width="80px" />
    <h5 class="viber-title">{{ "VIBER.TITLE" | translate }}</h5>
  </div>
  <div class="viber-content">
    <div class="speech align-items-center">
      <span>{{ "VIBER.MESSAGE-1" | translate }}</span>
    </div>
    <div *ngIf="isMobile" class="speech align-items-center mt-3">
      <span>{{ "VIBER.MESSAGE-2-MOBILE" | translate }}</span>
    </div>

    <div *ngIf="!isMobile" class="speech align-items-center mt-3">
      <span>{{ "VIBER.MESSAGE-2-DESKTOP" | translate }}</span>
    </div>
  </div>
  <div class="viber-footer d-flex flex-column justify-content-center align-items-center">
    <div *ngIf="!isMobile">
      <img height="100px" src="assets/img/viber-qr-prod.png" width="100px" />
    </div>
    <a class="viber-button align-items-center" href="viber://pa?chatURI=ekarhu">
      <div>
        {{ "VIBER.BUTTON" | translate }}
      </div>
    </a>
  </div>
</div>
