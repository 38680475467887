<div class="col-12 d-flex justify-content-between p-0 m-0">
  <span class="align-self-center">
    {{ "NOTIFICATION-MESSAGE.UPDATE-AVAILABLE" | translate }}
  </span>
  <span>
    <button (click)="reload()" mat-icon-button>
      <mat-icon>refresh</mat-icon>
    </button>
  </span>
</div>
