<mat-sidenav-container (backdropClick)="closeSidenav()" *ngIf="isReleased && !isLanding()" class="clm-container">
  <mat-sidenav #sidenav (keydown.escape)="closeSidenav()" class="text-white bg-white" disableClose position="end">
    <clm-sidemenu (clickSidenavButton)="toggleSidenav()" (emergencyCall)="openEmergencySheet()"></clm-sidemenu>
  </mat-sidenav>

  <mat-sidenav-content class="pb-2">
    <clm-header (clickSidenavButton)="toggleSidenav()"></clm-header>
    <!-- shitty solution... -->
    <router-outlet *ngIf="isReleased && !isLanding()"></router-outlet>
    <!-- end of shitty solution... -->
  </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="isReleased" class="build-time">
  <b>
    <div *ngIf="buildTime">Build: {{ buildTime }}</div>
  </b>
</div>
<!-- asd -->
<clm-viberbot></clm-viberbot>
<router-outlet *ngIf="isLanding()"></router-outlet>
