import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class ReleaseGuard implements CanActivate {
  constructor(private router: Router) {}

  public canActivate(): boolean {
    if (environment.released) {
      return true;
    }

    this.router.navigate(['/landing']);
    return false;
  }
}
