<h1 class="mb-0" mat-dialog-title>{{ data?.title | translate }}</h1>
<div mat-dialog-content>
  <h6>{{ data?.message | translate }}</h6>
</div>
<div *ngIf="!data.flipYesAndNo; else flipTemplate" class="row" mat-dialog-actions>
  <div class="col-12 d-flex justify-content-center mb-2">
    <button [mat-dialog-close]="true" class="btn-rounded" color="primary" mat-raised-button>
      {{ data?.yes | translate }}
    </button>
  </div>
  <div class="col-12 d-flex justify-content-center">
    <button [mat-dialog-close]="false" class="btn-rounded" color="primary" mat-button>
      {{ data?.no | translate }}
    </button>
  </div>
</div>
<ng-template #flipTemplate>
  <div class="row" mat-dialog-actions>
    <div class="col-12 d-flex justify-content-center mb-2">
      <button [mat-dialog-close]="false" class="btn-rounded" color="primary" mat-button>
        {{ data?.no | translate }}
      </button>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <button [mat-dialog-close]="true" class="btn-rounded" color="primary" mat-raised-button>
        {{ data?.yes | translate }}
      </button>
    </div>
  </div>
</ng-template>
