import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from '@app/core/device-detector';

@Component({
  selector: 'clm-viberbot',
  templateUrl: 'viberbot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViberbotComponent implements OnInit {
  public showViberChat: boolean;

  constructor(private deviceDetectorService: DeviceDetectorService) {}

  public get isMobile(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  ngOnInit(): void {
    this.showViberChat = false;
  }

  public onClickViber(): void {
    this.showViberChat = !this.showViberChat;
  }
}
