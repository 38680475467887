<h1 *ngIf="!shoudHideDirectionPicker" mat-dialog-title>
  {{ 'CLAIM.NEW-CLAIM.SKETCH.DIRECTION-DIALOG.TITLE' | translate }}
</h1>
<div mat-dialog-content>
  <div *ngIf="!shoudHideDirectionPicker" class="row">
    <div class="col">
      <mat-select
        #directionSelect
        [(ngModel)]="outData.direction"
        placeholder="{{ 'CLAIM.NEW-CLAIM.SKETCH.DIRECTION-DIALOG.TITLE' | translate }}"
      >
        <mat-option value="stationary">
          {{ 'CLAIM.NEW-CLAIM.SKETCH.DIRECTION-DIALOG.DIRECTIONS.STATIONARY' | translate }}
        </mat-option>
        <mat-option value="forward"
          >{{ 'CLAIM.NEW-CLAIM.SKETCH.DIRECTION-DIALOG.DIRECTIONS.FORWARD' | translate }}</mat-option
        >
        <mat-option value="backward"
          >{{ 'CLAIM.NEW-CLAIM.SKETCH.DIRECTION-DIALOG.DIRECTIONS.BACKWARD' | translate }}</mat-option
        >
      </mat-select>
    </div>
  </div>
  <div *ngIf="showErrorMsg" class="row">
    <mat-error class="col-sm-12">{{ 'CLAIM.NEW-CLAIM.SKETCH.DIRECTION-DIALOG.ERROR' | translate }}</mat-error>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field class="w-100">
        <input
          [(ngModel)]="outData.vehicleIdentifier"
          [disabled]="data?.vehicleIdentifier"
          [maxLength]="data && vehicleTypesWithoutPlate.includes(data.vehicleType) ? maxIdentifierLength : maxPlatenumberLength"
          [placeholder]="identifierPlaceHolder"
          matInput
        />
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button *ngIf="!shoudHideDirectionPicker" [mat-dialog-close]="false" mat-button>
    {{ 'COMMONS.CANCEL' | translate }}
  </button>
  <button
    (click)="closeDialog()"
    [disabled]="outData.vehicleIdentifier?.length > maxIdentifierLength"
    id="add-direction"
    mat-button
  >
    {{ 'COMMONS.ADD' | translate }}
  </button>
</div>
