import { Component } from '@angular/core';

@Component({
  selector: 'clm-not-supported',
  template: `
    <div>Az ön böngészője nem támogatott.</div>
    <div>Your browser is not supported.</div>
  `,
})
export class NotSupportedComponent {}
