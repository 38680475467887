import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerDialogData } from './SpinnerDialogData';

@Component({
  selector: 'clm-spinner-dialog',
  templateUrl: 'spinner-dialog.html',
})
export class SpinnerDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: SpinnerDialogData) {}
}
