import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';

@Pipe({
  name: 'asFormArray',
  pure: true,
})
export class AsFormArrayPipe implements PipeTransform {
  public transform(value: AbstractControl): FormArray {
    return value as FormArray;
  }
}
