import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { getJsonResource } from '@app/util/http/http.util';
import { MenuItem } from '../core/types/MenuItem';

@Injectable({
  providedIn: 'root',
})
export class MenuService implements OnDestroy {
  private landingMenuItemList: MenuItem[];
  private landingMenuItemSubscription: Subscription;
  private menuItemList: MenuItem[];
  private menuItemSubscription: Subscription;

  constructor(private httpClient: HttpClient) {
    this.menuItemSubscription = getJsonResource(this.httpClient, 'claim-menu-list.json')
      .pipe(map((formMenu: HttpResponse<Object>) => formMenu.body))
      .subscribe((list: MenuItem[]) => {
        this.menuItemList = list;
      });

    this.landingMenuItemSubscription = getJsonResource(this.httpClient, 'claim-landing-menu-list.json')
      .pipe(map((formMenu: HttpResponse<Object>) => formMenu.body))
      .subscribe((list: MenuItem[]) => {
        this.landingMenuItemList = list;
      });
  }

  public get landingMenuOptions(): MenuItem[] {
    return this.landingMenuItemList;
  }

  public get menuOptions(): MenuItem[] {
    return this.menuItemList;
  }

  public ngOnDestroy(): void {
    this.menuItemSubscription.unsubscribe();
    this.landingMenuItemSubscription.unsubscribe();
  }
}
