import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationDialogData } from './NotificationDialogData';

@Component({
  selector: 'clm-notification-dialog',
  templateUrl: './notification-dialog.component.html',
})
export class NotificationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: NotificationDialogData) {}
}
