import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { InfoButtonModule } from '@shared/info-button';
import { MediaStatusDialogComponent } from './media-status-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    TranslateModule.forChild(),
    MatIconModule,
    InfoButtonModule,
  ],
  declarations: [MediaStatusDialogComponent],
})
export class MediaStatusDialogModule {}
