import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccidentInfoQrContent, OtherPartyToNotify, Role } from '@app/core/types';

@Component({
  selector: 'clm-notify-parties',
  templateUrl: './notify-parties.component.html',
})
export class NotifyPartiesComponent implements OnInit {
  public form: FormGroup;
  public readonly roleList: Role[] = [
    {
      text: 'CLAIM.NOTIFY-PARTIES.OPERATOR',
      value: 'OP',
    },
    {
      text: 'CLAIM.NOTIFY-PARTIES.OWNER',
      value: 'OW',
    },
    {
      text: 'CLAIM.NOTIFY-PARTIES.OTHER',
      value: 'OT',
    },
  ];

  private qr: AccidentInfoQrContent;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: { otherPartyToNotify?: OtherPartyToNotify },
    private dialogRef: MatDialogRef<NotifyPartiesComponent>
  ) {}

  public close(): void {
    if (this.form.valid) {
      const output = this.qr
        ? this.qr
        : {
            tIE: this.form.get('email').value,
            tIR: this.form.get('role').value,
          };
      this.dialogRef.close(output);
    }
  }

  public compareWith(r1: string, r2: string): boolean {
    return r1 && r2 && r1 === r2;
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
      role: ['', [Validators.required]],
    });

    if (this.data && this.data.otherPartyToNotify) {
      this.form.get('email').setValue(this.data.otherPartyToNotify.email);
      this.form.get('role').setValue(this.data.otherPartyToNotify.roleCode);
    }
  }

  public readQrCode(qr: AccidentInfoQrContent): void {
    this.form.get('email').setValue(qr.tIE);
    this.form.get('role').setValue(qr.tIR);
    this.qr = {
      iC: qr.iC,
      pN: qr.pN,
      tIE: qr.tIE,
      tIR: qr.tIR,
      tIN: qr.tIN,
      prelEmail: qr.tIE,
      prelRoleCode: qr.tIR,
      prelFullname: qr.tIN,
    };
  }
}
