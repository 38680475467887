<h1 mat-dialog-title>{{ 'CLAIM.NEW-CLAIM.SKETCH.DIALOG.TITLE' | translate }}</h1>
<div mat-dialog-content>
  <mat-form-field class="w-100">
    <input [(ngModel)]="text" matInput placeholder="{{'CLAIM.NEW-CLAIM.SKETCH.DIALOG.PLACEHOLDER' | translate }}" />
  </mat-form-field>
</div>
<div class="d-flex justify-content-center" mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'COMMONS.CANCEL' | translate }}</button>
  <button [mat-dialog-close]="text" cdkFocusInitial mat-button>{{ 'COMMONS.ADD' | translate }}</button>
</div>
