import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DeviceDetectorService, OS } from '@app/core/device-detector';

@Component({
  selector: 'clm-download-app-sheet',
  templateUrl: 'download-app-sheet.component.html',
})
export class DownloadAppSheetComponent implements OnInit, AfterViewInit {
  public isAndroid: boolean;
  public isIOS: boolean;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<DownloadAppSheetComponent>,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  public closeBottonSheet() {
    this.bottomSheetRef.dismiss();
  }

  public ngAfterViewInit(): void {
    this.setDeviceType();
  }

  public ngOnInit(): void {
    // Empty now..
  }

  public openLink(event: MouseEvent): void {
    this.closeBottonSheet();
    event.preventDefault();
  }

  private setDeviceType() {
    const os = this.deviceDetectorService.getDeviceInfo().os;

    if (os === OS.IOS || os === OS.MAC) {
      this.isIOS = true;
      this.isAndroid = false;
    } else if (os === OS.ANDROID) {
      this.isIOS = false;
      this.isAndroid = true;
    }
  }
}
