<section>
  <mat-dialog-content>
    <div *ngIf="!currentDevice" class="d-flex justify-content-center align-self-center">
      <mat-spinner color="primary"></mat-spinner>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field class="w-100">
          <mat-select [(ngModel)]="currentDevice">
            <mat-option *ngFor="let device of devices | async; let i = index" [value]="device">
              Camera {{ i + 1 }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <zxing-scanner
      #scanner
      (scanSuccess)="readData($event)"
      [device]="currentDevice"
      [formats]="['QR_CODE']"
      class="{{ currentDevice ? 'd-block' : 'd-none' }}"
      tryHarder="true"
    ></zxing-scanner>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button (click)="close()" class="col-12 bg-mabisz-primary text-white" mat-raised-button>Bezár</button>
  </mat-dialog-actions>
</section>
