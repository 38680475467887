import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DrawingVideoDialogData } from './DrawingVideoDialogData';

@Component({
  selector: 'clm-drawing-video-dialog',
  templateUrl: './drawing-video-dialog.component.html',
})
export class DrawingVideoDialogComponent implements OnInit {
  public idx: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DrawingVideoDialogData) {}

  public ngOnInit(): void {
    this.idx = 0;
  }

  public next(): void {
    if (this.idx !== 7) {
      this.idx += 1;
    }
  }

  public closeDialog(): void {
    document.getElementById('okBtn').click();
  }

  public previous(): void {
    if (this.idx !== 0) {
      this.idx -= 1;
    }
  }

  public jumpToVideo(nextIdx: number): void {
    this.idx = nextIdx;
  }
}
