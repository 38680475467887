import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmDialogModule } from '@app/shared/confirm-dialog';
import { NotificationDialogModule } from '@app/shared/notification-dialog';
import { Constants } from './constants/constants.class';
import { DrawingVideoDialogModule } from './drawing-video-dialog';
import { KknyrNotificationDialogModule } from './kknyr-notification-dialog';
import { MaterialModule } from './material-module';
import { MediaStatusDialogModule } from './media-status-dialog/media-status-dialog.module';
import { NotifyPartiesModule } from './notify-parties/notify-parties.module';
import { DurationFormatPipe } from './pipes/duration-format.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { QrReaderButtonModule } from './qr-reader-button/qr-reader-button.module';
import { SpinnermDialogModule } from './spinner-dialog';
import { TelephoneModule } from './telephone/telephone.module';
import { AsFormControlPipe } from '@shared/pipes/as-form-control.pipe';
import { AsFormArrayPipe } from '@shared/pipes/as-form-array.pipe';
import { AsFormGroupPipe } from '@shared/pipes/as-form-group.pipe';
import { ClmAlphanumericInputDirective } from '@shared/directives/alphanumeric-input/clm-alphanumeric-input.directive';

@NgModule({
  declarations: [
    DurationFormatPipe,
    KeysPipe,
    SafeUrlPipe,
    AsFormControlPipe,
    AsFormArrayPipe,
    AsFormGroupPipe,
    ClmAlphanumericInputDirective,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NotificationDialogModule,
    DrawingVideoDialogModule,
    KknyrNotificationDialogModule,
    SpinnermDialogModule,
    ConfirmDialogModule,
    MediaStatusDialogModule,
    NotifyPartiesModule,
    QrReaderButtonModule,
    TelephoneModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    NotificationDialogModule,
    DrawingVideoDialogModule,
    KknyrNotificationDialogModule,
    SpinnermDialogModule,
    ConfirmDialogModule,
    NotifyPartiesModule,
    DurationFormatPipe,
    KeysPipe,
    SafeUrlPipe,
    MediaStatusDialogModule,
    TelephoneModule,
    AsFormControlPipe,
    AsFormArrayPipe,
    AsFormGroupPipe,
    ClmAlphanumericInputDirective,
  ],
  providers: [Constants],
})
export class SharedModule {}
