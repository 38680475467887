import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { first, tap } from 'rxjs/operators';
import { DocumentService } from '@app/core/document/document.service';
import { LanguageService } from '../sidemenu/language.service';
import { HomeDialogComponent } from './../shared/home-dialog/home-dialog.component';

@Component({
  selector: 'clm-header',
  templateUrl: 'header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Output() public clickSidenavButton: EventEmitter<any> = new EventEmitter();
  public defaultLang: FormControl;
  public homeUrl: string = environment.homeUrl;
  public isTraining: boolean = environment.isTrainingMode;
  public languages: any[];

  constructor(
    private dialog: MatDialog,
    private languageService: LanguageService,
    private docService: DocumentService
  ) {
    this.languages = languageService.languages;
    this.defaultLang = languageService.defaultLang;
  }

  public get isTraingingEnabled(): boolean {
    return environment.isTrainingMode;
  }

  public onChangeLanguage(lang) {
    this.languageService.changeLangue(lang);
    this.languageService.setDateLang(lang);
  }

  public openAszf(): void {
    this.docService
      .getAszf()
      .pipe(
        tap((blob: Blob) => window.open(window.URL.createObjectURL(blob), '_blank')),
        first()
      )
      .subscribe();
  }

  public openHomeDialog(): void {
    this.dialog.open(HomeDialogComponent, {
      data: {
        showHomeButton: false,
      },
    });
  }

  public openSidenav() {
    this.clickSidenavButton.emit(undefined);
  }

  public trackByFn(index: number): number {
    return index;
  }
}
