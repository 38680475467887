import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Constants from './device-detector.constants';
import { ReTree } from './retree';

export interface DeviceInfo {
  browser: string;
  browser_version: string;
  device: string;
  os: string;
  os_version: string;
  userAgent: string;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectorService {
  private browser = '';
  private browser_version = '';
  private device = '';
  private os = '';
  private os_version = '';
  private ua = '';
  private userAgent = '';

  constructor(@Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      this.ua = window.navigator.userAgent;
    }
    this._setDeviceInfo();
  }

  public getDeviceInfo(): DeviceInfo {
    const deviceInfo: DeviceInfo = {
      userAgent: this.userAgent,
      os: this.os,
      browser: this.browser,
      device: this.device,
      os_version: this.os_version,
      browser_version: this.browser_version,
    };
    return deviceInfo;
  }

  public isDesktop() {
    return [Constants.DEVICES.PS4, Constants.DEVICES.CHROME_BOOK, Constants.DEVICES.UNKNOWN].some((item) => {
      return this.device === item;
    });
  }

  public isMobile(): boolean {
    return [
      Constants.DEVICES.ANDROID,
      Constants.DEVICES.IPHONE,
      Constants.DEVICES.I_POD,
      Constants.DEVICES.BLACKBERRY,
      Constants.DEVICES.FIREFOX_OS,
      Constants.DEVICES.WINDOWS_PHONE,
      Constants.DEVICES.VITA,
    ].some((item) => {
      return this.device === item;
    });
  }

  public isTablet() {
    return [Constants.DEVICES.I_PAD, Constants.DEVICES.FIREFOX_OS].some((item) => {
      return this.device === item;
    });
  }

  private _setDeviceInfo() {
    const reTree = new ReTree();
    const ua = this.ua;
    this.userAgent = ua;
    const mappings = [
      { const: 'OS', prop: 'os' },
      { const: 'BROWSERS', prop: 'browser' },
      { const: 'DEVICES', prop: 'device' },
      { const: 'OS_VERSIONS', prop: 'os_version' },
    ];

    mappings.forEach((mapping) => {
      this[mapping.prop] = Object.keys(Constants[mapping.const]).reduce((obj: any, item: any) => {
        obj[Constants[mapping.const][item]] = reTree.test(ua, Constants[`${mapping.const}_RE`][item]);
        return obj;
      }, {});
    });

    mappings.forEach((mapping) => {
      this[mapping.prop] = Object.keys(Constants[mapping.const])
        .map((key) => {
          return Constants[mapping.const][key];
        })
        .reduce((previousValue, currentValue) => {
          return previousValue === Constants[mapping.const].UNKNOWN && this[mapping.prop][currentValue]
            ? currentValue
            : previousValue;
        }, Constants[mapping.const].UNKNOWN);
    });

    this.browser_version = '0';
    if (this.browser !== Constants.BROWSERS.UNKNOWN) {
      const re = Constants.BROWSER_VERSIONS_RE[this.browser];
      const res = reTree.exec(ua, re);
      if (!!res) {
        this.browser_version = res[1];
      }
    }
  }
}
