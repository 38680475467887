import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CustomValidators } from '@app/claim/new-claim/custom-validators';
import { Constants } from '../constants/constants.class';

@Component({
  selector: 'clm-telephone',
  templateUrl: './telephone.component.html',
})
export class TelephoneComponent implements OnInit, OnDestroy {
  public static readonly areaCodesForMobilePhone = [20, 30, 70];
  public static readonly areaCodesForWiredPhone = [1, 31, 38, 50];
  public static readonly notAllowedProviders = [40, 60, 80, 90];
  public countryControl: AbstractControl;
  @Input() public defaultValidators: {
    country: ValidatorFn[];
    other: ValidatorFn[];
    provider: ValidatorFn[];
  };
  public floating = 'auto';
  public focusOther = false;
  public focusProvider = false;
  @Input() public formGroup: FormGroup;
  public otherControl: AbstractControl;
  @ViewChild('phoneOther', { static: true }) public otherInputField: ElementRef;
  public phoneControl: AbstractControl;
  @Output() public phoneNumberEvent = new EventEmitter<string>();
  @ViewChild('phoneProvider', { static: true })
  public phoneProvider: ElementRef;
  public providerControl: AbstractControl;
  private destroy$: Subject<void> = new Subject<void>();
  @ViewChildren(MatFormFieldControl) private matFormFields: QueryList<MatFormFieldControl<any>>;

  public get inValid(): boolean {
    return (
      (this.providerControl.invalid && this.providerControl.touched) ||
      (this.otherControl.invalid && this.otherControl.touched)
    );
  }

  public get phoneNumber(): string {
    return `${this.countryControl.value}|${this.providerControl.value}|${this.otherControl.value}`.replace(
      /[\s+]/g,
      ''
    );
  }

  public get showErrorMesseage(): boolean {
    return (
      this.phoneControl.invalid &&
      (this.countryControl.touched || this.otherControl.touched || this.providerControl.touched)
    );
  }

  public get showLengthErrorMesseage(): boolean {
    return (
      !!this.phoneControl.get('country').getError('maxlength') ||
      !!this.phoneControl.get('provider').getError('maxlength') ||
      !!this.phoneControl.get('other').getError('maxlength')
    );
  }

  public get valid(): boolean {
    return (
      (this.providerControl.valid || this.otherControl.valid) &&
      this.countryControl.value !== '' &&
      this.hasAnyFocused()
    );
  }

  public hasAnyFocused(): boolean {
    return (
      this.matFormFields &&
      this.matFormFields.filter((control) => control.focused).length >= 1 &&
      this.matFormFields.filter((control) => control.errorState).length === 0
    );
  }

  public makePhoneValid(): void {
    this.providerControl.clearValidators();
    this.otherControl.clearValidators();
    this.providerControl.markAsUntouched();
    this.otherControl.markAsUntouched();
    this.providerControl.updateValueAndValidity({ emitEvent: false });
    this.otherControl.updateValueAndValidity({ emitEvent: false });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    this.phoneControl = this.formGroup.get('phone');
    this.countryControl = this.phoneControl.get('country');
    this.providerControl = this.phoneControl.get('provider');
    this.otherControl = this.phoneControl.get('other');

    this.countryControl.valueChanges
      .pipe(
        filter((value: string) => value === null),
        switchMap(() => this.providerControl.valueChanges),
        filter((value: string) => value === null),
        switchMap(() => this.otherControl.valueChanges),
        filter((value: string) => value === null),
        tap(() => (this.floating = 'auto')),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.providerControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((val: string) => !!val),
        map((value: string) => value.replace(/[^0-9]/g, '')),
        tap((value: string) => {
          if (this.countryControl.value === '+36' && Constants.validAreaCodes.includes(Number.parseInt(value, 10))) {
            this.otherInputField.nativeElement.focus();
          }
        }),
        tap((val: string) => {
          if (this.countryControl.value !== '+36' && val.length === 5) {
            this.otherInputField.nativeElement.focus();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.countryControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((countryNumber: string) => {
          if (countryNumber && (countryNumber === '+36' || countryNumber.length >= 6)) {
            this.phoneProvider.nativeElement.focus();
          }
          if (
            (!countryNumber || countryNumber === '+36') &&
            this.defaultValidators.country.findIndex((value: ValidatorFn) => value === Validators.required) === -1
          ) {
            this.defaultValidators.other = this.defaultValidators.other.filter(
              (validatorFn: ValidatorFn) => validatorFn !== Validators.required
            );
            this.defaultValidators.provider = this.defaultValidators.provider.filter(
              (validatorFn: ValidatorFn) => validatorFn !== Validators.required
            );
          } else if (
            this.defaultValidators.provider.findIndex((value: ValidatorFn) => value === Validators.required) === -1 &&
            this.defaultValidators.other.findIndex((value: ValidatorFn) => value === Validators.required) === -1
          ) {
            this.defaultValidators.other.push(Validators.required);
            this.defaultValidators.provider.push(Validators.required);
          }
          this.validatePhoneOther();
          this.validatePhoneProvider();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.otherControl.valueChanges
      .pipe(
        debounceTime(25),
        distinctUntilChanged(),
        filter((value: string) => !!value),
        map((value: string) => value.replace(/[^0-9]/g, '')),
        // Formatting value
        tap((valueWithoutWhiteSpace: string) =>
          this.otherControl.setValue(valueWithoutWhiteSpace.replace(/^(.{3})(.*)$/, '$1 $2').trim())
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public onBlurPhoneCountry(): void {
    this.phoneNumberChanged();
  }

  public onBlurPhoneOther(): void {
    this.switchLabelFloating();
    this.validatePhoneProvider();
    this.focusOther = false;
    this.phoneNumberChanged();
  }

  public onBlurPhoneProvider(): void {
    this.switchLabelFloating();
    this.validatePhoneOther();
    this.focusProvider = false;
    this.phoneNumberChanged();
  }

  public onFocusPhoneOther(): void {
    this.floating = 'always';
    this.validatePhoneOther();
    this.focusOther = true;
  }

  public onFocusPhoneProvider(): void {
    this.floating = 'always';
    this.validatePhoneProvider();
    this.focusProvider = true;
  }

  public phoneNumberChanged(): void {
    if (
      this.countryControl.value === '+36' &&
      !this.providerControl.value &&
      !this.otherControl.value &&
      this.defaultValidators.country.findIndex((value: ValidatorFn) => value === Validators.required) === -1
    ) {
      this.makePhoneValid();
    }
    this.phoneNumberEvent.emit(this.phoneNumber);
  }

  public switchLabelFloating(): void {
    if (!this.providerControl.value && !this.otherControl.value) {
      this.floating = 'auto';
    }
  }

  public validatePhoneOther(): void {
    const validators = [];
    validators.push(...this.defaultValidators.other);
    if (this.countryControl.value === '+36') {
      validators.push(Validators.required);
      // if Hungarian number then validate areacodes
      const provider = Number.parseInt(this.providerControl.value, 10);
      const foundProviderMobile = TelephoneComponent.areaCodesForMobilePhone.find((val) => val === provider);
      const foundProviderWiredPhone = TelephoneComponent.areaCodesForWiredPhone.find((val) => val === provider);
      // ha nem bp vezetékes 7 (+ szóköz(8))
      if (foundProviderWiredPhone) {
        validators.push(...[Validators.maxLength(8), Validators.minLength(7)]);
      } else if (foundProviderMobile) {
        validators.push(...[Validators.maxLength(8), Validators.minLength(8)]);
      } else {
        validators.push(...[Validators.maxLength(7), Validators.minLength(7)]);
      }
    } else {
      validators.push(...[Validators.maxLength(19), Validators.minLength(1)]);
    }

    this.otherControl.setValidators(validators);
    this.otherControl.updateValueAndValidity();
  }

  public validatePhoneProvider(): void {
    const validators = [];
    validators.push(...this.defaultValidators.provider);
    if (this.countryControl.value === '+36') {
      validators.push(Validators.required);
      const joinedIgnoreProviders = TelephoneComponent.notAllowedProviders.join('|');
      validators.push(
        ...[
          Validators.maxLength(2),
          CustomValidators.areaCode(),
          Validators.pattern(`^((?!${joinedIgnoreProviders})[0-9]*)$`),
        ]
      );
    } else {
      validators.push(...[Validators.maxLength(5)]);
    }

    this.providerControl.setValidators(validators);
    this.providerControl.updateValueAndValidity();
  }
}
