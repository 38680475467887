import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Pipe({
  name: 'asFormControl',
  pure: true,
})
export class AsFormControlPipe implements PipeTransform {
  public transform(value: AbstractControl): FormControl {
    return value as FormControl;
  }
}
