<strong>{{ "EMERGENCY-CALL.TITLE" | translate }}</strong>

<mat-list>
  <mat-list-item>{{ "EMERGENCY-CALL.STEPS.0" | translate }}</mat-list-item>
  <mat-list-item>{{ "EMERGENCY-CALL.STEPS.1" | translate }}</mat-list-item>
  <mat-list-item>{{ "EMERGENCY-CALL.STEPS.2" | translate }}</mat-list-item>
  <mat-list-item>{{ "EMERGENCY-CALL.STEPS.3" | translate }}</mat-list-item>
  <mat-list-item>{{ "EMERGENCY-CALL.STEPS.4" | translate }}</mat-list-item>
</mat-list>

<mat-nav-list>
  <a (click)="openLink()" [href]="emergencyPhoneHref" class="bg-mabisz-secondary text-white" mat-list-item>
    <span class="text-center mr-3" mat-line>
      <mat-icon class="mr-2 align-middle">phone</mat-icon>
      <strong>112</strong>
    </span>
  </a>
</mat-nav-list>
