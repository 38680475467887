import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { first, tap } from 'rxjs/operators';
import { DocumentService } from '@app/core/document/document.service';
import { MenuItem } from '@app/core/types/MenuItem';
import { HomeDialogComponent } from '../shared/home-dialog/home-dialog.component';
import { LanguageService } from './language.service';
import { MenuService } from './menu.service';

@Component({
  selector: 'clm-sidemenu',
  templateUrl: 'sidemenu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidemenuComponent {
  @Output() public clickSidenavButton: EventEmitter<any> = new EventEmitter();
  public defaultLang: FormControl;
  @Output() public emergencyCall: EventEmitter<any> = new EventEmitter();
  public languages: any[];

  constructor(
    private dialog: MatDialog,
    private service: MenuService,
    private languageService: LanguageService,
    private docService: DocumentService
  ) {
    this.languages = languageService.languages;
    this.defaultLang = languageService.defaultLang;
  }

  public get options(): MenuItem[] {
    return this.service.menuOptions;
  }

  public callEmergency() {
    this.emergencyCall.emit(undefined);
  }

  public closeSidenav() {
    this.clickSidenavButton.emit(undefined);
  }

  public onChangeLanguage(lang) {
    this.languageService.changeLangue(lang);
    this.languageService.setDateLang(lang);
  }

  public openAszf(): void {
    this.docService
      .getAszf()
      .pipe(tap((blob: Blob) => window.open(window.URL.createObjectURL(blob), '_blank'), first()))
      .subscribe();
  }

  public openHomeDialog(): void {
    this.dialog.open(HomeDialogComponent, {
      data: {
        showHomeButton: false,
      },
    });
  }

  public trackByFn(index: number): number {
    return index;
  }
}
