import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from '@nsalaun/ng-logger';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AccidentInfoQrContent } from '@app/core/types';
import { QrScannerDialogComponent } from '@app/shared/qr-scanner-dialog/qr-scanner-dialog.component';

@Component({
  selector: 'clm-qr-reader-button',
  templateUrl: './qr-reader-button.component.html',
})
export class QrReaderButtonComponent implements OnDestroy {
  @Input() public buttonText: string;
  @Output() public readQr: EventEmitter<AccidentInfoQrContent> = new EventEmitter<AccidentInfoQrContent>();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private dialog: MatDialog, private logger: Logger) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onBtnQrCode(): void {
    this.dialog
      .open(QrScannerDialogComponent, {
        panelClass: 'clm-qr-scanner-popup',
      })
      .afterClosed()
      .pipe(
        filter((val: AccidentInfoQrContent) => !!val),
        tap((readQr: AccidentInfoQrContent) => this.mapScannedData(readQr)),
        tap((validatedQr: AccidentInfoQrContent) => this.readQr.emit(validatedQr)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private mapScannedData(data: AccidentInfoQrContent): AccidentInfoQrContent {
    return this.validateQrData(data);
  }

  private validateQrData(data: AccidentInfoQrContent): AccidentInfoQrContent {
    const privacyNumberMaxLength = 20;
    const firstNameAndMiddleNameMaxLength = 25;
    const contractorLastNameMaxLength = 60;
    const contractorCompanyNameMaxLength = 110;
    const contractorAddressMaxLength = 60;
    const vehicleProductMaxLenght = 43;
    const vehicleKindMaxLength = 50;
    const licensePlateMaxlength = 9;

    // remove leading zero
    if (data.vC) {
      data.vC = data.vC[0] === '0' ? data.vC.slice(1) : data.vC;
    }

    data.fullName =
      data.cLN && data.cFN
        ? `${!data.cLN ? '' : data.cLN} ${!data.cFN ? '' : data.cFN} ${!data.cMN ? '' : data.cMN}`.slice(0, 110)
        : '';
    data.pN = !data.pN ? '' : data.pN.slice(0, privacyNumberMaxLength);
    data.cFN = !data.cFN ? '' : data.cFN.slice(0, firstNameAndMiddleNameMaxLength);
    data.cMN = !data.cMN ? '' : data.cMN.slice(0, firstNameAndMiddleNameMaxLength);
    data.cLN = !data.cLN ? '' : data.cLN.slice(0, contractorLastNameMaxLength);
    data.cCN = !data.cCN ? '' : data.cCN.slice(0, contractorCompanyNameMaxLength);
    data.cA = !data.cA ? '' : data.cA.slice(0, contractorAddressMaxLength);
    data.vP = !data.vP ? '' : data.vP.slice(0, vehicleProductMaxLenght);
    data.vK = !data.vK ? '' : data.vK.slice(0, vehicleKindMaxLength);
    data.lP = !data.lP ? '' : data.lP.slice(0, licensePlateMaxlength);

    this.logger.debug('After validation', data);

    return data;
  }
}
