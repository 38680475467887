import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'clm-drawing-text-dialog',
  templateUrl: 'text-dialog.html',
})
export class DrawingTextDialogComponent {
  public text: string;

  constructor(public dialogRef: MatDialogRef<DrawingTextDialogComponent>) {}
}
