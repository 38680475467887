<h1 *ngIf="data?.title" matDialogTitle>{{ data.title | translate }}</h1>
<h1 *ngIf="data?.translatedTitle" matDialogTitle>{{ data.translatedTitle }}</h1>
<mat-dialog-content>
  <div class="d-flex flex-column align-content-center justify-content-center">
    <div *ngIf="data?.showText" [innerHTML]="data?.text | translate" class="text-center"></div>
    <div class="d-flex align-content-center-center justify-content-center w-100">
      <div class="d-flex align-content-center align-items-center">
        <span
          (click)="previous()"
          [ngClass]="idx !== 0 ? 'primary-color' : 'disable-color'"
          class="p-0 pr-2"
          color="primary"
          mat-button
        >
          &lt;
        </span>
      </div>
      <div>
        <div *ngIf="idx === 0">
          <video (ended)="next()" autoplay controls width="200px">
            <source src="assets/video/video_0.mp4" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="idx === 1">
          <video (ended)="next()" autoplay controls width="200px">
            <source src="assets/video//video_1.mp4" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="idx === 2">
          <video (ended)="next()" autoplay controls width="200px">
            <source src="assets/video//video_2.mp4" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="idx === 3">
          <video (ended)="next()" autoplay controls width="200px">
            <source src="assets/video//video_3.mp4" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="idx === 4">
          <video (ended)="next()" autoplay controls width="200px">
            <source src="assets/video//video_4.mp4" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="idx === 5">
          <video (ended)="next()" autoplay controls width="200px">
            <source src="assets/video//video_5.mp4" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="idx === 6">
          <video (ended)="next()" autoplay controls width="200px">
            <source src="assets/video//video_6.mp4" type="video/mp4" />
          </video>
        </div>
        <div *ngIf="idx === 7">
          <video (ended)="closeDialog()" autoplay controls width="200px">
            <source src="assets/video//video_7.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div class="d-flex align-content-center align-items-center">
        <span
          (click)="next()"
          [ngClass]="idx !== 7 ? 'primary-color' : 'disable-color'"
          class="p-0 pl-2"
          color="primary"
          mat-button
        >
          &gt;
        </span>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-around mt-3">
    <div
      (click)="jumpToVideo(0)"
      [ngClass]="idx === 0 ? 'video-active' : 'video-inactive'"
      class="video-circle cursor-pointer"
    ></div>
    <div
      (click)="jumpToVideo(1)"
      [ngClass]="idx === 1 ? 'video-active' : 'video-inactive'"
      class="video-circle cursor-pointer"
    ></div>
    <div
      (click)="jumpToVideo(2)"
      [ngClass]="idx === 2 ? 'video-active' : 'video-inactive'"
      class="video-circle cursor-pointer"
    ></div>
    <div
      (click)="jumpToVideo(3)"
      [ngClass]="idx === 3 ? 'video-active' : 'video-inactive'"
      class="video-circle cursor-pointer"
    ></div>
    <div
      (click)="jumpToVideo(4)"
      [ngClass]="idx === 4 ? 'video-active' : 'video-inactive'"
      class="video-circle cursor-pointer"
    ></div>
    <div
      (click)="jumpToVideo(5)"
      [ngClass]="idx === 5 ? 'video-active' : 'video-inactive'"
      class="video-circle cursor-pointer"
    ></div>
    <div
      (click)="jumpToVideo(6)"
      [ngClass]="idx === 6 ? 'video-active' : 'video-inactive'"
      class="video-circle cursor-pointer"
    ></div>
    <div
      (click)="jumpToVideo(7)"
      [ngClass]="idx === 7 ? 'video-active' : 'video-inactive'"
      class="video-circle cursor-pointer"
    ></div>
  </div>
</mat-dialog-content>

<div class="d-flex justify-content-center pt-3" mat-dialog-actions>
  <button
    *ngIf="data?.actionString"
    class="text-white btn-rounded mb-3"
    color="primary"
    id="okBtn"
    mat-button
    mat-raised-button
    matDialogClose
  >
    {{ data?.actionString | translate }}
  </button>
</div>
