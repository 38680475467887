import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'clm-home-dialog',
  templateUrl: './home-dialog.component.html',
})
export class HomeDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: object & { showHomeButton?: boolean }) {}
}
