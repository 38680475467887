<div class="h-100 col-12 d-flex">
  <button class="col-1" color="warn" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="h-100 container-background d-flex flex-wrap justify-content-around p-1">
    <ng-container *ngFor="let terrainFeature of data; let i = index; trackBy: trackByFn">
      <button (click)="addTerrainFeature(terrainFeature)" class="col-3" mat-button>
        <img
          height="40px"
          src="/assets/img/drawing/menu-items/terrain-feature/{{ terrainFeature.fileName }}.svg"
          width="40px"
        />
      </button>
    </ng-container>
  </div>
</div>
