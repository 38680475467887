import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@app/home/home.component';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { ReleaseGuard } from './shared/security/release.guard';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: 'landing',
    loadChildren: () => import('./landing').then((m) => m.LandingModule),
  },
  { path: 'home', component: HomeComponent, canActivate: [ReleaseGuard] },
  {
    path: 'cookie-consent',
    loadChildren: () => import('./cookie-consent').then((m) => m.CookieConsentModule),
  },
  {
    path: 'claim/new',
    loadChildren: () => import('./claim').then((m) => m.ClaimModule),
    canActivate: [ReleaseGuard],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
