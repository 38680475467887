import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './confirm-dialog.component';

const components: any[] = [ConfirmDialogComponent];

@NgModule({
  declarations: [components],
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
  exports: [components],
  providers: [],
})
export class ConfirmDialogModule {}
