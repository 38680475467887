import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { KknyrNotificationDialogData } from './KknyrNotificationDialogData';

@Component({
  selector: 'clm-kknyr-notification-dialog',
  templateUrl: './kknyr-notification-dialog.component.html',
})
export class KknyrNotificationDialogComponent {
  constructor(
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: KknyrNotificationDialogData
  ) {}

  public getText(data: any): string {
    if (data.plateNumber) {
      return (
        this.translateService.instant('CLAIM.KKNYR-CALL.KKNYR-STATUS-SUCCESFUL-1') +
        ' ' +
        data.plateNumber +
        ' ' +
        this.translateService.instant(data.text)
      );
    } else {
      return this.translateService.instant(data.text);
    }
  }
}
