import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subject } from 'rxjs';
import { filter, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { ReloadNotificationComponent } from './reload-notification/reload-notification.component';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  private static readonly twoMinutes = 1000 * 60 * 2;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(private swUpdate: SwUpdate, private snackBar: MatSnackBar) {
    this.swUpdate.available
      .pipe(
        take(1),
        tap(() => {
          this.snackBar.openFromComponent(ReloadNotificationComponent, { duration: 5000 });
        }),
        finalize(() => this.destroy$.next())
      )
      .subscribe();

    interval(UpdateService.twoMinutes)
      .pipe(
        filter(() => this.swUpdate.isEnabled),
        tap(() => this.swUpdate.checkForUpdate().then()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
