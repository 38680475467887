import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgLoggerModule } from '@nsalaun/ng-logger';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { NotFoundModule } from './not-found';

///////////////////////////////////
// Declare tree-shakeable tokens //
///////////////////////////////////
export const API_ENDPOINT = new InjectionToken<string>('apiEndpoint', {
  providedIn: 'root',
  factory: () => environment.baseApiUrl,
});

export const BUILD_TIME = new InjectionToken<string>('buildTime', {
  providedIn: 'root',
  factory: () => environment.buildTime,
});

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/i18n/');
}

/**
 * Components
 */
const coreComponents: any[] = [];

/**
 * Services
 */
const coreServices: any[] = [];

/**
 * Directives
 */
const coreDirectives: any[] = [];

/**
 * Pipes
 */
const corePipes: any[] = [];

@NgModule({
  imports: [
    CommonModule,
    NotFoundModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgLoggerModule.forRoot(environment.logLevel),
    ZXingScannerModule,
    DragAndDropModule,
    AgmCoreModule.forRoot({
      apiKey: environment.firebaseConfig.apiKey,
      libraries: ['places'],
    }),
    MatGoogleMapsAutocompleteModule,
  ],
  exports: [coreComponents, TranslateModule],
  declarations: [corePipes, coreDirectives, coreComponents],
  providers: [coreServices],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [coreServices],
    };
  }
}
