<div matDialogTitle>
  <div class="d-flex justify-content-between">
    <h4 class="d-flex mb-0">{{ "CLAIM.NEW-CLAIM.VEHICLES.NOTIFY-PARTIES" | translate }}</h4>
    <clm-info-button
      [dialogData]="{
        title: 'CLAIM.NEW-CLAIM.VEHICLES.NOTIFY-PARTIES',
        actionString: 'COMMONS.OK',
        text: 'CLAIM.NOTIFY-PARTIES.INFO'
      }"
      class="my-auto"
      theme="dark"
    ></clm-info-button>
  </div>
</div>

<mat-dialog-content>
  <form (keydown.enter)="close()" [formGroup]="form">
    <clm-qr-reader-button
      (readQr)="readQrCode($event)"
      [buttonText]="'CLAIM.NEW-CLAIM.VEHICLES.OTHER-PARTIES-QR'"
    ></clm-qr-reader-button>
    <mat-form-field class="col-sm-12">
      <input [maxLength]="100" formControlName="email" matInput placeholder="{{ 'COMMONS.EMAIL' | translate }}" />
    </mat-form-field>
    <mat-form-field class="col-sm-12">
      <mat-select
        [compareWith]="compareWith"
        formControlName="role"
        placeholder="{{ 'CLAIM.NOTIFY-PARTIES.ROLE' | translate }}"
      >
        <mat-option *ngFor="let _role of roleList" [value]="_role.value">
          {{ _role.text | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button color="warn" mat-button matDialogClose>{{ "COMMONS.CANCEL" | translate }}</button>
  <button (click)="close()" [disabled]="form.invalid" color="primary" mat-button>
    {{ "COMMONS.SAVE" | translate }}
  </button>
</mat-dialog-actions>
