import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { QrReaderButtonComponent } from './qr-reader-button.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, TranslateModule.forChild()],
  declarations: [QrReaderButtonComponent],
  exports: [QrReaderButtonComponent],
})
export class QrReaderButtonModule {}
