import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '@app/core/core.module';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private URL_DOCS = 'docs';

  constructor(@Inject(API_ENDPOINT) private apiEndpoint: string, private httpClient: HttpClient) {}

  public getAszf(): Observable<Blob> {
    return this.httpClient.get(`${this.apiEndpoint}/${this.URL_DOCS}/ASZF.pdf`, { responseType: 'blob' });
  }

  public getTermsAndConditions(): Observable<Blob> {
    return this.httpClient.get(`${this.apiEndpoint}/${this.URL_DOCS}/Adatvedelmi_tajekoztato.pdf`, {
      responseType: 'blob',
    });
  }
}
