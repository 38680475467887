import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class HungarianDateAdapter extends NativeDateAdapter {
  public getFirstDayOfWeek(): number {
    return 1;
  }

  public parse(value: any): Date | undefined {
    if (typeof value === 'string' && value.indexOf('.') > -1) {
      const str = value.split('.');
      if (str.length < 3 || isNaN(+str[0]) || isNaN(+str[1]) || isNaN(+str[2])) {
        return undefined;
      }
      return new Date(Number(str[0]), Number(str[1]) - 1, Number(str[2]), 12);
    }

    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? undefined : new Date(timestamp);
  }
}
