export enum VehicleTypeCode {
  CAR = 0,
  MOTORKEREKPAR = 1,
  BUS = 2,
  TROLI = 3,
  TRUCK = 4,
  NYERGES_VONTATO = 5,
  POTKOCSI = 6,
  MEZGAZ_VONTATO = 7,
  MUNKAGEP = 8,
  LASSU = 9,
  ROBOGO = 10,
  NEGYKEREKU_SMKP = 11,
  KEREKPAR = 12,
}
