import { NotFoundComponent } from './not-found.component';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

/**
 * Components
 */
const notFoundComponents: any[] = [NotFoundComponent];

@NgModule({
  imports: [CommonModule, TranslateModule.forChild()],
  declarations: [notFoundComponents],
})
export class NotFoundModule {}
