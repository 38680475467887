import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, EMPTY, from, fromEvent, Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, finalize, tap } from 'rxjs/operators';
import { VerificationStore } from '../store/verification.store';

@Component({
  selector: 'clm-media-status-dialog',
  templateUrl: './media-status-dialog.component.html',
})
export class MediaStatusDialogComponent implements OnInit, AfterViewInit {
  public _internalIsFinishedSending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public allMediaCount = 0;
  public allMediaCount$: Observable<number>;
  public failedMediaFiles: Array<Observable<any>> = [];
  public internalIsFinishedSending$: Observable<boolean> = this._internalIsFinishedSending.asObservable();
  public isFinishedSendingMediaFiles$: Observable<boolean>;
  public remainingDays$: Observable<number>;
  @ViewChild('retryBtn', { static: true }) public retryButton: MatButton;
  public successfullyUploadedMediaCount = 0;
  public successfulMediaUpload$: Observable<void>;
  private failedMedaFiles$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private verificationStore: VerificationStore
  ) {}

  public ngAfterViewInit(): void {
    fromEvent(this.retryButton._elementRef.nativeElement, 'click')
      .pipe(
        filter(() => this.failedMediaFiles.length > 0),
        exhaustMap(() => this.retrySend())
      )
      .subscribe();

    this.remainingDays$ = this.verificationStore.remainingDaysForMediaFiles;
  }

  public ngOnInit(): void {
    this.iconRegistry.addSvgIcon('pipa', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/pipa.svg'));
    this.iconRegistry.addSvgIcon('fail', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/fail.svg'));

    this.allMediaCount$ = this.data.allMediaCount;
    this.successfulMediaUpload$ = this.data.successfulMediaUpload;
    this.isFinishedSendingMediaFiles$ = this.data.isFinishedSendingMediaFiles;
    this.failedMedaFiles$ = this.data.failedMediaFiles;

    this.failedMedaFiles$.subscribe(
      (mediaCall: Observable<any>) => (this.failedMediaFiles = [...this.failedMediaFiles, mediaCall])
    );
    this.successfulMediaUpload$.subscribe(() => this.successfullyUploadedMediaCount++);
    this.allMediaCount$.subscribe((val: number) => (this.allMediaCount = val));
  }

  private retrySend() {
    return from(this.failedMediaFiles).pipe(
      tap(() => this._internalIsFinishedSending.next(false)),
      concatMap((call) =>
        of(call).pipe(
          exhaustMap((_call) => _call),
          tap(() => {
            this.failedMediaFiles.shift();
            this.successfullyUploadedMediaCount++;
          }),
          catchError(() => EMPTY)
        )
      ),
      finalize(() => this._internalIsFinishedSending.next(true))
    );
  }
}
