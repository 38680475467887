import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFormat',
  pure: false,
})
export class DurationFormatPipe implements PipeTransform {
  public transform(value: number, formatFrom: string, formatTo: string): string {
    switch (formatFrom) {
      case 's':
        return this.format(formatTo, value);
      default:
        throw new Error(`Unsupported format (from): ${formatFrom}`);
    }
  }

  private format(formatTo: string, time: number): string {
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor((time / 60) % 60);
    const formattedMinute = minutes < 10 ? `0${minutes}` : minutes;
    let formattedSecond = `00`;
    if (seconds < 0) {
      const foo = Math.abs(seconds);
      formattedSecond = foo.toString().length < 2 ? `-0${foo}` : `-${foo}`;
    } else {
      const foo = Math.abs(seconds);
      formattedSecond = foo.toString().length < 2 ? `0${foo}` : `${foo}`;
    }

    switch (formatTo) {
      case 'ss':
        return `${formattedSecond}`;
      case 'mmss':
        return `${formattedMinute}:${formattedSecond}`;
      default:
        throw new Error(`Unsupported format (to): ${formatTo}`);
    }
  }
}
