import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TerrainFeature } from '@app/core/types';

@Component({
  selector: 'clm-terrain-element-container-dialog',
  templateUrl: 'terrain-element-container.html',
})
export class TerrainElementContainerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TerrainElementContainerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: object[] & Array<{ fileName: string; type: TerrainFeature }>
  ) {}

  public addTerrainFeature(terrainFeature): void {
    this.dialogRef.close(terrainFeature);
  }

  public close() {
    this.dialogRef.close();
  }

  public trackByFn(index: number): number {
    return index;
  }
}
