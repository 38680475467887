import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VehicleTypeCode } from '@app/core/types';

@Component({
  selector: 'clm-traffic-element-container-dialog',
  templateUrl: 'traffic-element-container.html',
})
export class TrafficElementContainerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TrafficElementContainerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: object[] & Array<{ fileName: string; type: VehicleTypeCode }>
  ) {}

  public close() {
    this.dialogRef.close();
  }

  public onClickAddTrafficSign(trafficSign): void {
    this.dialogRef.close(trafficSign);
  }

  public trackByFn(index: number): number {
    return index;
  }
}
