<div *ngIf="isIOS" class="pb-2">
  <div class="col-4 d-inline-block align-middle">
    <div class="mabisz-app-store-ic"></div>
  </div>
  <div class="col-8 d-inline-block align-middle">
    <div class="mb-1">
      <small>{{ "DOWNLOAD-APP.DESCRIPTION-IOS" | translate }}</small>
    </div>
    <div>
      <a href="https://itunes.apple.com/app/id1448391210" rel="noreferrer" target="_blank">
        <button class="bg-primary text-white mr-1" mat-raised-button>{{ "DOWNLOAD-APP.DOWNLOAD" | translate }}</button>
      </a>
      <button (click)="closeBottonSheet()" class="" mat-raised-button>{{ "DOWNLOAD-APP.CANCEL" | translate }}</button>
    </div>
  </div>
</div>

<div *ngIf="isAndroid" class="pb-2">
  <div class="col-4 d-inline-block align-middle">
    <div class="mabisz-app-store-ic"></div>
  </div>
  <div class="col-8 d-inline-block align-middle">
    <div class="mb-1 d-flex">
      <small>{{ "DOWNLOAD-APP.DESCRIPTION-ANDROID" | translate }}</small>
    </div>
    <div>
      <a href="https://play.google.com/store/apps/details?id=hu.mabisz.ekarbejelento" rel="noreferrer" target="_blank">
        <button class="bg-primary text-white mr-1" mat-raised-button>{{ "DOWNLOAD-APP.DOWNLOAD" | translate }}</button>
      </a>
      <button (click)="closeBottonSheet()" class="" mat-raised-button>{{ "DOWNLOAD-APP.CANCEL" | translate }}</button>
    </div>
  </div>
</div>
