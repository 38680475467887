import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[clmAlphaNumericInput]',
})
export class ClmAlphanumericInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    if (event instanceof InputEvent) {
      const pattern = /^[a-zA-Z0-9]*$/;

      if (event.target instanceof HTMLInputElement) {
        if (!pattern.test(event.target.value)) {
          event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
        }

        event.target.value = event.target.value.toUpperCase();
      }
    }
  }
}
