<h1 *ngIf="data?.title" matDialogTitle>{{ data.title | translate }}</h1>
<h1 *ngIf="data?.translatedTitle" matDialogTitle>{{ data.translatedTitle }}</h1>
<mat-dialog-content>
  <div class="d-flex flex-column justify-content-center">
    <div [innerHTML]="data?.text | translate" class="text-center"></div>
    <div *ngIf="data.fillTime" class="pt-3 d-flex flex-column justify-content-center">
      <div class="text-center font-weight-bold">{{ "CLAIM.FINISH.LENGTH-OF-CLAIM" | translate }}</div>
      <div class="text-center">{{ data.fillTime }}</div>
    </div>
  </div>
</mat-dialog-content>

<div class="d-flex justify-content-center" mat-dialog-actions>
  <button
    *ngIf="data?.actionString"
    class="text-white btn-rounded mb-3"
    color="primary"
    mat-button
    mat-raised-button
    matDialogClose
  >
    {{ data?.actionString | translate }}
  </button>
</div>
