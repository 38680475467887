import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../../shared/constants/constants.class';
import { VehicleInfoService } from './vehicle-info';

export class CustomValidators {
  public static validAreaCodes = Constants.validAreaCodes;

  public static areaCode(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (!c.value) {
        return null;
      }
      return !!this.validAreaCodes.find((value) => value === Number.parseInt(c.value, 10))
        ? null
        : { areaInvalid: true };
    };
  }

  public static minDate(days: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const currentDate = new Date(c.value);
      const maxDate = new Date();
      const minDate = new Date(new Date(maxDate.getTime() - days * 24 * 60 * 60 * 1000).toDateString());

      if (!(currentDate >= minDate && currentDate <= maxDate)) {
        return { date: true };
      }
      return null;
    };
  }

  public static samePlateNumber(vehicleInfoService: VehicleInfoService, tabIndex: number) {
    return (c: AbstractControl): Observable<{ [key: string]: boolean }> | null => {
      return vehicleInfoService.checkSamePlateNumber(c.value, tabIndex).pipe(
        map((res) => {
          return res ? { samePlateNmber: true } : null;
        })
      );
    };
  }
}
