<button
  (click)="onBtnQrCode()"
  class="col-12 col-md-8 col-lg-6 d-block m-auto p-2 btn-rounded"
  color="primary"
  mat-raised-button
  type="button"
>
  <mat-icon>camera_alt</mat-icon>
  {{ buttonText | translate }}
</button>
