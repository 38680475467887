import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { VehicleTypeCode } from '@app/core/types';
import { TranslateService } from '@ngx-translate/core';
import { DirectionDialogData } from '@app/core/types/DirectionDialogData';

@Component({
  selector: 'clm-direction-picker-dialog',
  templateUrl: 'direction-picker.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectionPickerDialogComponent implements OnInit {
  @ViewChild('directionSelect') public directionSelect: MatSelect;
  public readonly maxIdentifierLength = 100;
  public readonly maxPlatenumberLength = 9;
  public showErrorMsg: boolean;
  public outData: DirectionDialogData = {
    direction: this.directionSelect && this.directionSelect.value,
  };

  public shoudHideDirectionPicker = false;

  public readonly vehicleTypesWithoutPlate = [
    VehicleTypeCode.KEREKPAR,
    VehicleTypeCode.MUNKAGEP,
    VehicleTypeCode.ROBOGO,
  ];

  constructor(
    public dialogRef: MatDialogRef<DirectionPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DirectionDialogData,
    private translateService: TranslateService
  ) {}

  public get identifierPlaceHolder(): string {
    if (this.data && this.vehicleTypesWithoutPlate.includes(this.data.vehicleType)) {
      return this.translateService.instant('CLAIM.NEW-CLAIM.SKETCH.DIRECTION-DIALOG.VEHICLE-IDENTIFIER');
    }

    return this.translateService.instant('CLAIM.NEW-CLAIM.SKETCH.DIRECTION-DIALOG.REGISTRATION-NUMBER');
  }

  public ngOnInit() {
    if (this.outData.direction === null || this.outData.direction === undefined) {
      this.outData.direction = 'forward';
    }
    if (this.data && this.data.vehicleIdentifier) {
      this.outData.vehicleIdentifier = this.data.vehicleIdentifier;
    }
    if (this.data && this.data.shouldHideDirection) {
      this.shoudHideDirectionPicker = this.data.shouldHideDirection;
    }
    this.showErrorMsg = false;
  }

  public closeDialog() {
    if (!this.shoudHideDirectionPicker && this.directionSelect && this.directionSelect.empty) {
      this.showErrorMsg = true;
    } else {
      this.dialogRef.close(this.outData);
    }
  }
}
