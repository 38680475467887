import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EmergencyCallSheetComponent } from '@app/emergency-call';
import { Observable, range } from 'rxjs';
import { toArray } from 'rxjs/operators';

@Component({
  selector: 'clm-home',
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  @Input() public showHomeButton: boolean;
  public stepCount$: Observable<number[]>;

  constructor(private bottomSheet: MatBottomSheet) {
    this.stepCount$ = range(0, 7).pipe(toArray());
    this.showHomeButton = !!this.showHomeButton || typeof this.showHomeButton === 'undefined';
  }

  public ngOnInit(): void {
    sessionStorage.clear();
  }

  public openEmergencyCallSheet(): void {
    this.bottomSheet.open(EmergencyCallSheetComponent);
  }

  public trackByFn(index: number): number {
    return index;
  }
}
