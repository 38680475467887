import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { environment } from '@env/environment';

@Component({
  selector: 'clm-emergency-call-sheet',
  templateUrl: 'emergency-call-sheet.component.html',
})
export class EmergencyCallSheetComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<EmergencyCallSheetComponent>) {}

  public get emergencyPhoneHref(): string {
    return `tel:${environment.emergencyPhoneNum}`;
  }

  public openLink(): void {
    this.bottomSheetRef.dismiss();
  }
}
