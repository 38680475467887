import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ApplicationRef, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserDetect } from '@app/util/http/browser-detect';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DirectionPickerDialogComponent } from './claim/new-claim/drawing/direction-picker/direction-picker.component';
import { TerrainElementContainerDialogComponent } from './claim/new-claim/drawing/terrain-element-container/terrain-element-container.component';
import { DrawingTextDialogComponent } from './claim/new-claim/drawing/text-dialog/text-dialog.component';
import { TrafficElementContainerDialogComponent } from './claim/new-claim/drawing/traffic-element-container/traffic-element-container.component';
import { CoreModule } from './core/core.module';
import { ReloadNotificationComponent } from './core/update-sw/reload-notification/reload-notification.component';
import { DownloadAppSheetComponent } from './download-app';
import { EmergencyCallSheetComponent } from './emergency-call';
import { HeaderComponent } from './header';
import { HomeComponent } from './home/home.component';
import { NotSupportedComponent } from './not-supported.component';
import { SharedModule } from './shared';
import { HungarianDateAdapter } from './shared/date-adapter/hungarian-date-adapter';
import { HomeDialogComponent } from './shared/home-dialog/home-dialog.component';
import { KknyrNotificationDialogModule } from './shared/kknyr-notification-dialog';
import { NotificationDialogModule } from './shared/notification-dialog/notification-dialog.module';
import { ReleaseGuard } from './shared/security/release.guard';
import { SpinnermDialogModule } from './shared/spinner-dialog';
import { SidemenuComponent } from './sidemenu';
import { ViberbotComponent } from './viberbot';
import { GestureConfig } from '../gesture-config';

const appComponents: any[] = [
  HeaderComponent,
  SidemenuComponent,
  ViberbotComponent,
  EmergencyCallSheetComponent,
  DownloadAppSheetComponent,
  DrawingTextDialogComponent,
  DirectionPickerDialogComponent,
  TrafficElementContainerDialogComponent,
  TerrainElementContainerDialogComponent,
  HomeDialogComponent,
  HomeComponent,
  ReloadNotificationComponent,
  NotSupportedComponent,
];

@NgModule({
  declarations: [AppComponent, appComponents],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    NotificationDialogModule,
    KknyrNotificationDialogModule,
    SpinnermDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    HammerModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ReleaseGuard,
    {
      provide: DateAdapter,
      useClass: HungarianDateAdapter,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
  ],
})
export class AppModule {
  constructor(private injector: Injector) {}

  public ngDoBootstrap(applicationRef: ApplicationRef): void {
    const browserDetect = new BrowserDetect();
    if (browserDetect.browser !== 'Explorer') {
      applicationRef.bootstrap(AppComponent);
    } else {
      const element = createCustomElement(NotSupportedComponent, {
        injector: this.injector,
      });
      customElements.define('clm-root', element);
    }
  }
}
