<div>
  <div class="d-flex justify-content-center">
    <div class="m-3 d-flex justify-content-between col-12">
      <div>&nbsp;</div>

      <div>
        <div class="mabisz-logo-sm"></div>
      </div>

      <div>
        <mat-icon (click)="closeSidenav()" class="btn mt-2 mr-4 p-0 mat-icon-md">menu</mat-icon>
      </div>
    </div>
  </div>

  <mat-list>
    <mat-list-item class="d-block d-md-none mb-2 w-100">
      <button (click)="callEmergency()" class="text-white w-100" mat-button>
        <h5 class="p-2 m-0 w-100 text-left">
          <mat-icon class="text-mabisz-secondary align-middle mr-2">phone</mat-icon>
          <span class="align-middle text-mabisz-secondary">{{ "MENU.EMERGENCY-CALL" | translate }}</span>
        </h5>
      </button>
    </mat-list-item>

    <mat-list-item class="mb-2 w-100">
      <button (click)="closeSidenav(); openHomeDialog()" [routerLink]="'home'" mat-button>
        <h5 class="p-2 m-0 w-100 text-left">
          <mat-icon class="align-middle mr-2">error_outline</mat-icon>
          <span class="align-middle">{{ "MENU.SAFETY-NOTES" | translate }}</span>
        </h5>
      </button>
    </mat-list-item>
    <mat-list-item class="mb-2 w-100">
      <button (click)="closeSidenav(); openAszf()" mat-button>
        <h5 class="p-2 m-0 w-100 text-left">
          <mat-icon class="align-middle mr-2">info</mat-icon>
          <span class="align-middle">{{ "MENU.TERMS-AND-CONDITIONS" | translate }}</span>
        </h5>
      </button>
    </mat-list-item>

    <mat-list-item class="mb-2 w-100">
      <div class="pl-4 p-2 mt-4 m-0 w-100 text-left w-100">
        <mat-icon class="align-middle mr-2">flag</mat-icon>
        <span class="align-middle">
          <mat-form-field class="">
            <mat-select
              (selectionChange)="onChangeLanguage($event.value)"
              [formControl]="defaultLang"
              placeholder="{{ 'MENU.LANGUAGE' | translate }}"
            >
              <mat-option *ngFor="let language of languages; let i = index; trackBy: trackByFn" [value]="language.code">
                {{ language.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
    </mat-list-item>
  </mat-list>
</div>
