import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { InfoButtonModule } from '../info-button';
import { QrReaderButtonModule } from './../qr-reader-button/qr-reader-button.module';
import { NotifyPartiesComponent } from './notify-parties.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    QrReaderButtonModule,
    InfoButtonModule,
  ],
  declarations: [NotifyPartiesComponent],
})
export class NotifyPartiesModule {}
